<template>
  <div class="pay-with-credit-card">
    <Container>
      <Card padding="medium">
        <Margins>
          <CardHeader
            :header="
              $t('CREDIT_CARD.PAYMENT.YOU_HAVE_CHOSEN', [
                selectedBundle.bundle.name,
              ])
            "
          />
          <ApplyAssetCard />
          <ApplyYourPlan :showPaymentAmount="false" />
        </Margins>

        <div class="margins__triple">
          <b-row align-v="center" align-h="center" class="margins__double">
            <Button
              @click="showCheckoutModal"
              border="none"
              variant="inverse"
              :loading="isLoading"
            >
              {{ $t('CREDIT_CARD.PAYMENT.CONFIRM_PURCHASE') }}
            </Button>
          </b-row>

          <b-row align-v="center" align-h="center" class="margins__double">
            <Button
              @click="$router.back()"
              border="none"
              variant="ghost"
              :loading="isLoading"
            >
              {{ $t('CREDIT_CARD.PAYMENT.CANCEL') }}
            </Button>
          </b-row>
        </div>
      </Card>
    </Container>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { Card, Container, Margins, Button, CardHeader } from '@/components';
import { ApplyAssetCard, ApplyYourPlan } from '@/containers/Apply';
import { constants } from '@/mixins';
import { HAS_ERROR } from '@/types';

export default {
  name: 'Reject',
  mixins: [constants],
  components: {
    Container,
    Card,
    Margins,
    Button,
    CardHeader,
    ApplyAssetCard,
    ApplyYourPlan,
  },
  data() {
    return {
      checkoutModal: null,
      isLoading: false,
      accepted: false,
    };
  },
  mounted() {
    if (this.loanApplicationDecision == null) {
      this.$router.push({ name: this.ROUTES.HOME.name });
    } else {
      this.checkoutModal = new window.Reepay.ModalCheckout();
      this.checkoutModal
        .addEventHandler(window.Reepay.Event.Accept, () => {
          this.accepted = true;
        })
        .addEventHandler(window.Reepay.Event.Cancel, () => {})
        .addEventHandler(window.Reepay.Event.Close, () => {
          this.accepted &&
            this.$router.push({
              name: this.ROUTES.LOAN_APPLICATION_SUCCESS.name,
            });
        })
        .addEventHandler(window.Reepay.Event.Error, () => {
          this.$router.push({
            name: this.ROUTES.CREDIT_CARD_PAYMENT_FAIL.name,
          });
        });
    }
  },
  beforeDestroy() {
    this.checkoutModal?.destroy();
  },
  computed: {
    ...mapState(['loanApplicationDecision', 'selectedBundle']),
  },
  methods: {
    ...mapMutations([HAS_ERROR]),
    async showCheckoutModal() {
      try {
        this.isLoading = true;
      } catch (error) {
        this.HAS_ERROR(error);
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
